import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/myedibleenso.github.io/myedibleenso.github.io/src/components/BasicLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`For this assignment, you're going to follow through with the exercise described in the `}<strong parentName="p">{`creating a professional profile`}</strong>{` unit](/courses/hlt-pro/unit-2/your-professional-profile/#creating-a-professional-profile) and add your writeup to `}<a parentName="p" {...{
        "href": "https://github.com/uazhlt-ms-program/professionalism-for-hlt",
        "target": "_self",
        "rel": "nofollow"
      }}>{`https://github.com/uazhlt-ms-program/professionalism-for-hlt`}</a>{`.`}</p>
    <p>{`In your directory, add the following to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<net-id>/assignment-2.md`}</code>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "markdown"
    }}><pre parentName="div" {...{
        "className": "language-markdown"
      }}><code parentName="pre" {...{
          "className": "language-markdown"
        }}><span parentName="code" {...{
            "className": "token title important"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`###`}</span>{` Briefly describe your career goal`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`<!-- 
delete this comment and add your response here.
Following your last exercise, 
reiterate your career goal as crisply as possible here 
(in terms of role, company, activities, etc.) 
Try to be as specific as possible (1-2 short sentences).
-->`}</span>{`

`}<span parentName="code" {...{
            "className": "token title important"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`###`}</span>{` Your LinkedIn profile URL`}</span>{`

`}<span parentName="code" {...{
            "className": "token bold"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`**`}</span><span parentName="span" {...{
              "className": "token content"
            }}>{`link`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`**`}</span></span>{`: ??? 

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`<!--
On your LinkedIn profile, ...
- [ ] List only relevant work experience for your career goals
  - each entry should have bullets summarizing key contributions
- [ ] List any publications you have (even if this means reproducing information from your ORCID profile or CV)
- [ ] Under **Projects**, list any projects you contribute to that relate to your career goals
- [ ] Document your professional achievements
  - promotions, awards, new job titles, etc.
-->`}</span>{`

`}<span parentName="code" {...{
            "className": "token title important"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`###`}</span>{` What is your profile headline?`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`<!-- 
Reproduce your profile's headline here.  
Some options to consider for the 120 characters: 
a) a succinct summary of who you are professionally, 
b) your chief career goal, 
c) what you're looking for next, 
d) what you can offer an organization.  
You can consider combining two or more of these as A | B | C.
-->`}</span>{`

`}<span parentName="code" {...{
            "className": "token title important"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`###`}</span>{` What is your professional introduction?`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`<!-- 
Reproduce your professional introduction here (your profile's **About** section).  
The introduction should expand on your profile headline 
by highlighting your relevant skills, experience, and career goals.
-->`}</span>{`

`}<span parentName="code" {...{
            "className": "token title important"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`####`}</span>{` What profiles did you reference when crafting your introduction?`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`<!--
Referencing the **About** sections 
of **early career professionals** 
in your target career can help you craft a strong **About** section.  
It will give you a chance 
to reflect on what makes you unique 
and the key strengths 
you need to highlight.
-->`}</span>{`
`}<span parentName="code" {...{
            "className": "token bold"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`**`}</span><span parentName="span" {...{
              "className": "token content"
            }}>{`Related profile 1 (URL)`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`**`}</span></span>{`: ???
`}<span parentName="code" {...{
            "className": "token bold"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`**`}</span><span parentName="span" {...{
              "className": "token content"
            }}>{`Related profile 2 (URL)`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`**`}</span></span>{`: ???
`}<span parentName="code" {...{
            "className": "token bold"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`**`}</span><span parentName="span" {...{
              "className": "token content"
            }}>{`Related profile 3 (URL)`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`**`}</span></span>{`: ???

`}<span parentName="code" {...{
            "className": "token title important"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`###`}</span>{` Grow your network by at least three people`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`<!--
Consider connecting with peers, 
former employers, 
or industry professionals you've interacted with in the past. 
Feel free to connect with your instructor as well!
-->`}</span>{`

`}<span parentName="code" {...{
            "className": "token bold"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`**`}</span><span parentName="span" {...{
              "className": "token content"
            }}>{`Connection 1 (LinkedIn profile URL)`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`**`}</span></span>{`: ???

`}<span parentName="code" {...{
            "className": "token bold"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`**`}</span><span parentName="span" {...{
              "className": "token content"
            }}>{`Connection 2 (LinkedIn profile URL)`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`**`}</span></span>{`: ???

`}<span parentName="code" {...{
            "className": "token bold"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`**`}</span><span parentName="span" {...{
              "className": "token content"
            }}>{`Connection 3 (LinkedIn profile URL)`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`**`}</span></span>{`: ???

`}<span parentName="code" {...{
            "className": "token title important"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`####`}</span>{` [optional] If you sent an introductory message to your new connections, please share it below`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`<!--
[OPTIONAL] delete this comment and include the introductory message(s) you used here.
-->`}</span>{`

`}<span parentName="code" {...{
            "className": "token title important"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`###`}</span>{` Describe two specific topics you could post to your LinkedIn feed to demonstrate your knowledge/expertise.`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`<!-- 
You don't have to post anything at this point, 
but simply brainstorm possiblities.  
Active users on LinkedIn who share relevant content 
benefit by increasing their visibility in LinkedIn searches and growing their network. 
Content can demonstrate relevant expertise as well as strongly held opinions 
that can help define your cultural fit at an organization.
-->`}</span>{`

`}<span parentName="code" {...{
            "className": "token title important"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`####`}</span>{` Topic 1`}</span>{`

`}<span parentName="code" {...{
            "className": "token title important"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`####`}</span>{` Topic 2`}</span></code></pre></div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      